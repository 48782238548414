import { TwitchIcon } from 'lucide-react';

import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction
} from '@remix-run/node';
import { Link, useLoaderData } from '@remix-run/react';
import { Alert } from '@web/components/ui/alert';
import { Button } from '@web/components/ui/button';
import { advertiserSignInRoute } from '@web/routes/auth';
import { streamerHomeRoute } from '@web/routes/public';
import { authenticator } from '@web/utilities/authenticator';
import { notAuthenticatedMiddleware } from '@web/utilities/middlewares';
import { jsonWithFlash } from '@web/utilities/responses';

import { LogoWebsite } from '../../public/homeStreamer/Logo/LogoWebsite';

import backgroundImageDesktop from './background_login_desktop.webp';
import backgroundImageMobile from './background_login_mobile.webp';
import backgroundImageTablet from './background_login_tablet.webp';

export const meta: MetaFunction = () => {
  return [
    { title: 'Streamer Sign In | Onezee' },
    {
      name: 'description',
      content:
        'Log in to your streamer account. Manage your campaigns, track performance and revenue.'
    }
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const appRedirect = await notAuthenticatedMiddleware(request);
  return appRedirect ? appRedirect() : jsonWithFlash(request, {});
}

export async function action({ request }: ActionFunctionArgs) {
  return authenticator.authenticate('twitch', request);
}

export default function Login() {
  const data = useLoaderData<any>();

  return (
    <div className="flex min-h-screen flex-col items-center bg-slate-900 xl:flex-row">
      <div className="hidden xl:block xl:w-[55%]">
        <img
          src={backgroundImageDesktop}
          alt="Background desktop"
          className="h-screen w-full object-cover"
        />
      </div>
      <div className="flex w-full max-w-md flex-col items-center px-4 pt-6 md:max-w-2xl lg:max-w-3xl xl:w-[45%] xl:max-w-none xl:justify-center xl:px-0">
        <Link to={streamerHomeRoute()} prefetch="intent" className="mt-6">
          <LogoWebsite
            variant="whiteText"
            className="h-[28px] w-[117px] md:h-[34px] md:w-[142px] xl:h-[35px] xl:w-[146px]"
          />
        </Link>

        <h1 className="mt-[72px] max-w-[360px] text-center font-inter text-32 font-semibold leading-tight text-white md:mt-24 md:max-w-[600px] md:text-48 xl:mt-4 xl:max-w-[684px] xl:text-40 xl:leading-[140%]">
          Connecting Streamers with Advertisers for Mutual Success
        </h1>

        <p className="mt-[72px] text-center font-inter text-base font-medium text-white md:mt-24 md:text-xl xl:mt-16 xl:text-base">
          Login to your account
          <br className="hidden xl:inline" /> using your Twitch
        </p>

        {!!data?.error && (
          <Alert variant="destructive" className="mt-4 w-full xl:max-w-md">
            {data.error}
          </Alert>
        )}
        {!!data?.message && (
          <Alert variant="success" className="mt-4 w-full xl:max-w-md">
            {data.message}
          </Alert>
        )}

        <form
          method="post"
          className="mt-2 flex w-full flex-col items-center justify-center md:mt-6 xl:mt-6"
        >
          <Button
            size="lg"
            variant="twitter"
            className="w-full gap-2 rounded-xl md:w-[296px]"
          >
            <TwitchIcon size={20} /> Log in with Twitch
          </Button>
        </form>
        <p className="mt-4 text-center font-inter text-sm md:mt-6 md:text-base xl:mt-6 xl:text-sm">
          <span className="font-normal text-slate-300">Not a streamer? </span>
          <Link
            to={advertiserSignInRoute()}
            prefetch="intent"
            className="font-medium text-white underline"
          >
            Log in as an advertiser
          </Link>
        </p>
      </div>
      <div className="mt-10 w-full xl:hidden">
        <img
          src={backgroundImageMobile}
          alt="Background mobile"
          className="size-full object-cover md:hidden"
        />
        <img
          src={backgroundImageTablet}
          alt="Background tablet"
          className="hidden size-full object-cover md:block xl:hidden"
        />
      </div>
    </div>
  );
}
